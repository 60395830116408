<template>
  <div>
    <AppTable
      :rows="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: swap.limit,
        count: swap.count,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <AssetFilter v-model="selectAsset" @change="changeAsset" />
          </b-col>

          <b-col md="4" xl="4" class="mb-1">
            <b-form-group>
              <label for="datepicker-placeholder">Status</label>
              <b-form-select v-model="selectStatus" :options="statusOptions" @change="changeStatus" />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search" type="text" />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'user'" class="flex-center-align ">
          <b-button variant="link" @click="row.user.action" target="_blank" class="text-left">
            {{ row.user.name }}
          </b-button>
        </span>
        
        <span v-else-if="column.field === 'userWallet'" class="flex-center-align">
          <b-button variant="link" @click="doCopy(row.userWallet.raw)" class="p-1 font-16 text-left">
            {{ row.userWallet.text }}
          </b-button>
        </span>

        <span v-else-if="column.field === 'status'" class="flex-center-align">
          <b-badge variant="primary" class="text-white">
            {{ row.status || '' }}
          </b-badge>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import AssetFilter from '@/components/containers/AssetFilter.vue'
import AppTable from '@/components/AppTable.vue'
import { formattedDate, formatLongString } from '@/tools/util'
import { swapStatusTypes } from '@/views/apps/swap/config/swapTypes'
import doCopy from '@/mixins/doCopy'

export default {
  name: 'SwapTransactions',
  components: {
    BBadge,
    AppTable,
    AssetFilter,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, doCopy],
  data() {
    return {
      columns,

      searchTerm: '',
      selectAsset: '',

      selectStatus: '',
      statusOptions: ['', ...Object.values(swapStatusTypes)],
    }
  },

  computed: {
    ...mapGetters({
      transactions: 'transactions/transactions',
    }),

    swap() {
      return this.transactions.swap
    },

    rows() {
      return this.swap.value?.map(item => {
        const createdAt = item.createdAt ? this.formattedDate(item.createdAt) : ''

        const userWalletAddress = item.from?.wallet?.address || ''
        const userWallet = {
          text: this.formatLongString(userWalletAddress),
          raw: userWalletAddress,
        }

        const { emoji: fromEmoji = '', symbol: fromSymbol = '', amount: amountExpectedFrom = 0 } = item.from || {}
        const pay = `${fromEmoji ?? ''} ${fromSymbol} ${amountExpectedFrom}`

        const { emoji: toEmoji = '', symbol: toSymbol = '', amount: amountExpectedTo = 0 } = item.to || {}
        const get = `${toEmoji ?? ''} ${toSymbol} ${amountExpectedTo}`

        const { rate: coinRate = 0 } = item
        const rate = `1 ${fromSymbol ?? ''} ~ ${coinRate} ${toSymbol}`

        const { service = {}, blockchain = {}, relayer = {} } = item.fees || {}
        const relayerFee = `${relayer?.amount || 0} ${relayer?.symbol || ''}`
        const serviceFee = `${service.amount || 0} ${service.symbol || ''} ${relayer?.amount ? relayerFee : ''}`
        const networkFee = `${blockchain.amount || 0} ${blockchain.symbol || ''}`

        const { id: userId, telegramUsername = '', firstName = '', lastName = '', avatar = '', email = '' } = item.user || {}
        const user = {
          action: () => this.$router.push(`/user/${userId}`),
          name: `${telegramUsername} ${firstName} ${lastName}`,
          avatar: avatar,
          link: '',
          id: userId,
        }

        return {
          ...item,
          user,
          userWallet,
          pay,
          get,
          rate,
          createdAt,
          serviceFee,
          networkFee,
        }
      })
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    formattedDate,
    formatLongString,
    ...mapActions({
      fetchSwap: 'transactions/fetchSwap',
    }),

    initState() {
      const query = this.createQueryConfig()

      this.getSwap(query)
    },

    search() {
      const query = this.createQueryConfig({ userTelegramName: this.searchTerm, page: 1 })

      return this.getSwap(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getSwap(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getSwap(query)
    },

    changeAsset(asset) {
      const query = this.createQueryConfig({ coinId: asset?.id || null })

      return this.getSwap(query)
    },

    changeType(type) {
      const query = this.createQueryConfig({ type })

      return this.getSwap(query)
    },

    changeStatus(status) {
      const query = this.createQueryConfig({ status })

      return this.getSwap(query)
    },

    createQueryConfig(config) {
      return {
        page: this.swap.page,
        limit: this.swap.limit,

        userTelegramName: this.searchTerm || undefined,
        coinId: this.selectAsset?.id || undefined,
        type: this.selectType || undefined,
        status: this.selectStatus || undefined,

        ...config,
      }
    },

    getSwap(query) {
      return this.waitRequest(() => {
        return this.fetchSwap(query).catch(this.checkErrorsAlert)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
